<template>
  <div>
    <BaseModal
      dataClass="definition-item-modify-modal"
      size="lg"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <template v-slot:main>
        <div data-class="work-list" class="mx-2 my-2">
          <BaseTabGroup
            ref="tab-group"
            :inputModel="receiveOrderDetailInfo"
            :inputComponents="inputComponents"
          ></BaseTabGroup>
          <br />
        </div>
      </template>
      <template v-slot:footer="{ cancel }">
        <!-- 設定ボタン -->
        <BaseButton
          variant="primary"
          icon="check-circle"
          :columnInfo="resultControlInfo.setting"
          @click="setting(obj)"
        />
        <!-- 閉じるボタン -->
        <BaseButton
          variant="primary"
          icon="times-circle"
          :columnInfo="resultControlInfo.close"
          @click="cancel"
        />
      </template>
    </BaseModal>
    <!--部署モーダル -->
    <DepartmentModal
      id="department-modal-three"
      type="entry"
      displayModals="three"
      :selectedSearchCondition="departmentModalSearchCondition"
      @after-close-set="afterCloseSetDepartment"
      @after-close-unset="afterCloseUnsetDepartment"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 場所マスタモーダル -->
    <PlaceModal
      id="place-modal-edit"
      displayModals="two"
      type="entry"
      @after-close-set="afterCloseSetPlace"
      @after-close-unset="afterCloseUnsetPlace"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- Vehicleモーダル -->
    <VehicleModal
      id="vehicle-modal-edit"
      type="entry"
      selectMode="single"
      displayModals="two"
      @after-close-set="afterCloseSetVehicle"
      @after-close-unset="afterCloseUnsetVehicle"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 荷姿参照モーダル-->
    <ProductModal
      id="product-modal"
      type="entry"
      selectMode="single"
      @after-close-set="afterCloseSetProduct"
      @after-close-unset="afterCloseUnsetProduct"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
  </div>
</template>

<script>
import ReceiveOrderDetailInfo from './ReceiveOrderDetailInfo.js'
import { getListOptions, openSubModal } from '@/common/Common.js'
import {
  SCREEN_ID,
  DEPARTMENT_KIND,
  INTEGER_MAX_MIN,
  VEHICLE_CONDITION,
  HIGHWAY_USE_YN_FLAG,
} from '@/common/const.js'
import { init, setting } from '@/order/receiveorder/ReceiveOrderDetailEdit.js'

const screenId = SCREEN_ID.RECEIVE_ORDER_DETAIL_EDIT

export default {
  components: {
    DepartmentModal: () => import('@/master/department/DepartmentModal.vue'),
    PlaceModal: () => import('@/master/place/PlaceModal.vue'),
    VehicleModal: () => import('@/master/vehicle/VehicleModal.vue'),
    ProductModal: () => import('@/master/product/ProductModal.vue'),
  },
  props: {
    selectedItem: {
      type: Object,
    },
    screenKbn: {
      type: Number,
    },
    getDatas: {
      type: Array,
    },
  },

  watch: {
    getDatas() {
      init(this)
    },
  },

  data() {
    return {
      receiveOrderDetailInfo: new ReceiveOrderDetailInfo(),
      /**
       * 作業データリスト.
       * @type {Array}
       */
      tableItems: [],
      placeModalKbn: 0,
      obj: this,
      selectRowIdx: -1,
      departmentModalSearchCondition: {},
    }
  },

  computed: {
    inputComponents() {
      return [
        {
          // タブ
          label: this.detailInfo.tab1.label,
          inputComponents: [
            {
              // Item情報
              type: 'referenceCodeName',
              codeId: 'productCode',
              nameId: 'productName',
              codeColumnInfo: this.detailInfo.product_code,
              nameColumnInfo: this.detailInfo.product_name,
              btnColumnInfo: this.detailInfo.sub_modal_product,
              codeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              nameRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onClickCallback: () => {
                this.openSubModal(this, 'product-modal')
              },
            },
            {
              // タリフ判別商品コード
              type: 'text',
              id: 'tariffProductCode',
              columnInfo: this.detailInfo.tariff_product_code,
              maxLength: 50,
            },
            {
              // 数量
              type: 'text',
              id: 'quantity',
              columnInfo: this.detailInfo.quantity,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 質量
              type: 'text',
              id: 'weight',
              columnInfo: this.detailInfo.weight,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.FLOAT,
              ],
            },
            {
              // 容量
              type: 'text',
              id: 'volume',
              columnInfo: this.detailInfo.volume,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.FLOAT,
              ],
            },
            {
              // 長さ
              type: 'text',
              id: 'length',
              columnInfo: this.detailInfo.length,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 幅
              type: 'text',
              id: 'width',
              columnInfo: this.detailInfo.width,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 高さ
              type: 'text',
              id: 'height',
              columnInfo: this.detailInfo.height,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 自動区間分割可否
              type: 'select',
              id: 'autoSectionDivisions',
              columnInfo: this.detailInfo.auto_section_divisions,
              options: getListOptions(this.MASTER_CODE.AUTO_SECTION_DIVISIONS),
              onChangeCallback: () => {},
            },
            {
              // Edit
              // 出荷可能日時
              type: 'dateTime',
              dateId: 'departureBeginDateEdit',
              timeId: 'departureBeginTimeEdit',
              dateColumnInfo: this.detailInfo.departure_begin_date_edit,
              timeColumnInfo: this.detailInfo.departure_begin_time_edit,
              //locale: this.locale,
              fromTimeRules: [this.INPUT_TYPE_CHECK.DATETIMERANGE],
            },
            {
              // Edit
              // 出発終了日時
              type: 'dateTime',
              dateId: 'departureEndDateEdit',
              timeId: 'departureEndTimeEdit',
              dateColumnInfo: this.detailInfo.departure_end_date_edit,
              timeColumnInfo: this.detailInfo.departure_end_time_edit,
              //locale: this.locale,
              fromTimeRules: [this.INPUT_TYPE_CHECK.DATETIMERANGE],
            },
            {
              // 出荷場所
              type: 'referenceCodeName',
              codeId: 'departurePlaceCode',
              nameId: 'departurePlaceName',
              codeColumnInfo: this.detailInfo.departure_place_code,
              nameColumnInfo: this.detailInfo.departure_place_name,
              btnColumnInfo: this.detailInfo.sub_modal_departure_place,
              codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
              onClickCallback: () => {
                //...ボタン
                this.placeModalKbn = 1
                this.openSubModal(this, 'place-modal-edit')
              },
            },
            {
              // 出発場所指定フラグ
              type: 'select',
              id: 'departurePlaceAssignFlag',
              columnInfo: this.detailInfo.departure_place_assign_flag,
              options: getListOptions(this.MASTER_CODE.PLACE_ASSIGN_FLAG),
              onChangeCallback: () => {},
            },
            {
              // 出発時間指定区分
              type: 'select',
              id: 'departureTimeAssignKind',
              columnInfo: this.detailInfo.departure_time_assign_kind,
              options: getListOptions(this.MASTER_CODE.TIME_ASSIGN_KIND),
              onChangeCallback: () => {},
            },
            {
              // 出発時間厳格度
              type: 'select',
              id: 'departureTimeStrict',
              columnInfo: this.detailInfo.departure_time_strict,
              options: getListOptions(this.MASTER_CODE.TIME_STRICT),
              onChangeCallback: () => {},
            },
            {
              // Edit
              //到着開始日時
              type: 'dateTime',
              dateId: 'arrivalBeginDateEdit',
              timeId: 'arrivalBeginTimeEdit',
              dateColumnInfo: this.detailInfo.arrival_begin_date_edit,
              timeColumnInfo: this.detailInfo.arrival_begin_time_edit,
              //locale: this.locale,
              fromTimeRules: [this.INPUT_TYPE_CHECK.DATETIMERANGE],
            },
            {
              // Edit
              //到着終了日時
              type: 'dateTime',
              dateId: 'arrivalEndDateEdit',
              timeId: 'arrivalEndTimeEdit',
              dateColumnInfo: this.detailInfo.arrival_end_date_edit,
              timeColumnInfo: this.detailInfo.arrival_end_time_edit,
              //locale: this.locale,
              fromTimeRules: [this.INPUT_TYPE_CHECK.DATETIMERANGE],
            },
            {
              // 到着場所
              type: 'referenceCodeName',
              codeId: 'arrivalPlaceCode',
              nameId: 'arrivalPlaceName',
              codeColumnInfo: this.detailInfo.arrival_place_code,
              nameColumnInfo: this.detailInfo.arrival_place_name,
              btnColumnInfo: this.detailInfo.sub_modal_arrival_place, //Modal
              onClickCallback: () => {
                this.placeModalKbn = 2
                this.openSubModal(this, 'place-modal-edit')
              },
            },
            {
              // 到着時間指定区分
              type: 'select',
              id: 'arrivalTimeAssignKind',
              columnInfo: this.detailInfo.arrival_time_assign_kind,
              options: getListOptions(this.MASTER_CODE.TIME_ASSIGN_KIND),
              onChangeCallback: () => {},
            },
            {
              // 到着時間厳格度
              type: 'select',
              id: 'arrivalTimeStrict',
              columnInfo: this.detailInfo.arrival_time_strict,
              options: getListOptions(this.MASTER_CODE.TIME_STRICT),
              onChangeCallback: () => {},
            },
            {
              // 出発条件
              type: 'text',
              id: 'departureCondition',
              columnInfo: this.detailInfo.departure_condition,
            },
            {
              // 到着条件
              type: 'text',
              id: 'arrivalCondition',
              columnInfo: this.detailInfo.arrival_condition,
            },
            {
              // 備考
              type: 'text',
              id: 'note',
              columnInfo: this.detailInfo.note,
            },
            {
              // 輸送手段種類指定
              type: 'select',
              id: 'vehicleCategory',
              columnInfo: this.detailInfo.vehicle_category,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CATEGORY),
              onChangeCallback: () => {},
            },
            {
              // 指定車番
              type: 'referenceCodeName',
              codeId: 'assignVehicleCode',
              nameId: 'assignVehicleName',
              codeColumnInfo: this.detailInfo.assign_vehicle_code,
              nameColumnInfo: this.detailInfo.assign_vehicle_name,
              btnColumnInfo: this.detailInfo.sub_modal_vehicle,
              onClickCallback: () => {
                this.openSubModal(this, 'vehicle-modal-edit')
              },
            },
            {
              // トリップ番号指定
              type: 'text',
              id: 'assignTripNo',
              columnInfo: this.detailInfo.assign_trip_no,
              rules: [this.INPUT_TYPE_CHECK.ONLY_NUMERIC],
            },
            {
              // ドロップ番号指定
              type: 'text',
              id: 'assignDropNo',
              columnInfo: this.detailInfo.assign_drop_no,
              rules: [this.INPUT_TYPE_CHECK.ONLY_NUMERIC],
            },
            {
              // 指定運送会社
              type: 'referenceCodeName',
              codeId: 'assignCarrierCode',
              nameId: 'assignCarrierName',
              codeColumnInfo: this.detailInfo.assign_carrier_code,
              nameColumnInfo: this.detailInfo.assign_carrier_name,
              btnColumnInfo: this.detailInfo.sub_modal_assign_carrier,
              onClickCallback: () => {
                this.departmentModalSearchCondition = {}
                this.departmentModalSearchCondition.departmentKind =
                  DEPARTMENT_KIND.CARRIER
                this.openSubModal(this, 'department-modal-three')
              },
            },
            {
              // 自動配車対象外フラグ
              type: 'select',
              id: 'autoPlanningExcludeFlag',
              columnInfo: this.detailInfo.auto_planning_exclude_flag,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              options: getListOptions(
                this.MASTER_CODE.AUTO_PLANNING_EXCLUDE_FLAG
              ),
              onChangeCallback: () => {},
            },
            {
              //積置き指定フラグ
              type: 'select',
              id: 'preloadKind',
              columnInfo: this.detailInfo.preload_kind,
              options: getListOptions(this.MASTER_CODE.PRELOAD_KIND),
              onChangeCallback: () => {},
            },
            {
              // 積置き指定日
              type: 'date',
              id: 'preloadDate',
              columnInfo: this.detailInfo.preload_date,
            },
            {
              // 積置き指定時刻
              type: 'time',
              id: 'preloadBeginTime',
              columnInfo: this.detailInfo.preload_begin_time,
            },
            {
              //高速道路使用可否フラグ
              type: 'select',
              id: 'highwayUseYnFlag',
              columnInfo: this.detailInfo.highway_use_yn_flag,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              options: getListOptions(this.MASTER_CODE.HIGHWAY_USE_YN_FLAG),
              onChangeCallback: () => {},
            },
            {
              // オーダー距離
              type: 'text',
              id: 'orderDistance',
              columnInfo: this.detailInfo.order_distance,
              rules: [this.INPUT_TYPE_CHECK.FLOAT],
            },
            {
              //往復区分 -> 往路復路区分
              type: 'select',
              id: 'onewayOrRound',
              columnInfo: this.detailInfo.oneway_or_round,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              options: getListOptions(this.MASTER_CODE.GO_OR_BACK), //ONEWAY_OR_ROUND
              onChangeCallback: () => {},
            },
            {
              //2台積区分
              type: 'select',
              id: 'isMultipleLoading',
              columnInfo: this.detailInfo.is_multiple_loading,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              options: getListOptions(this.MASTER_CODE.IS_MULTIPLE_LOADING),
              onChangeCallback: () => {},
            },
            {
              // 注意事項
              type: 'text',
              id: 'warningMessage',
              columnInfo: this.detailInfo.warning_message,
            },
            {
              //シミュレーション取込データフラグ
              type: 'select',
              id: 'isSimulationData',
              columnInfo: this.detailInfo.is_simulation_data,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              options: getListOptions(this.MASTER_CODE.IS_SIMULATION_DATA),
              onChangeCallback: () => {},
            },
            {
              //処理状態
              type: 'select',
              id: 'processStatus',
              columnInfo: this.detailInfo.process_status,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              options: getListOptions(this.MASTER_CODE.PROCESS_STATUS),
              onChangeCallback: () => {},
            },
            {
              // 削除済み区分
              type: 'select',
              id: 'deleted',
              columnInfo: this.detailInfo.deleted,
              options: getListOptions(this.MASTER_CODE.DELETED),
              //rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
          ],
        },
        {
          // 車両条件タブ
          label: this.detailInfo.tab2.label,
          inputComponents: [
            {
              // 車両条件１
              type: 'select',
              id: 'vehicleCondition01',
              columnInfo: this.detailInfo.vehicle_condition01,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件２
              type: 'select',
              id: 'vehicleCondition02',
              columnInfo: this.detailInfo.vehicle_condition02,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件３
              type: 'select',
              id: 'vehicleCondition03',
              columnInfo: this.detailInfo.vehicle_condition03,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件４
              type: 'select',
              id: 'vehicleCondition04',
              columnInfo: this.detailInfo.vehicle_condition04,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件５
              type: 'select',
              id: 'vehicleCondition05',
              columnInfo: this.detailInfo.vehicle_condition05,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件６
              type: 'select',
              id: 'vehicleCondition06',
              columnInfo: this.detailInfo.vehicle_condition06,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件７
              type: 'select',
              id: 'vehicleCondition07',
              columnInfo: this.detailInfo.vehicle_condition07,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件８
              type: 'select',
              id: 'vehicleCondition08',
              columnInfo: this.detailInfo.vehicle_condition08,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件９
              type: 'select',
              id: 'vehicleCondition09',
              columnInfo: this.detailInfo.vehicle_condition09,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件１０
              type: 'select',
              id: 'vehicleCondition10',
              columnInfo: this.detailInfo.vehicle_condition10,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件１１
              type: 'select',
              id: 'vehicleCondition11',
              columnInfo: this.detailInfo.vehicle_condition11,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件１２
              type: 'select',
              id: 'vehicleCondition12',
              columnInfo: this.detailInfo.vehicle_condition12,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件１３
              type: 'select',
              id: 'vehicleCondition13',
              columnInfo: this.detailInfo.vehicle_condition13,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件１４
              type: 'select',
              id: 'vehicleCondition14',
              columnInfo: this.detailInfo.vehicle_condition14,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件１５
              type: 'select',
              id: 'vehicleCondition15',
              columnInfo: this.detailInfo.vehicle_condition15,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件１６
              type: 'select',
              id: 'vehicleCondition16',
              columnInfo: this.detailInfo.vehicle_condition16,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件１７
              type: 'select',
              id: 'vehicleCondition17',
              columnInfo: this.detailInfo.vehicle_condition17,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件１８
              type: 'select',
              id: 'vehicleCondition18',
              columnInfo: this.detailInfo.vehicle_condition18,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件１９
              type: 'select',
              id: 'vehicleCondition19',
              columnInfo: this.detailInfo.vehicle_condition19,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件２０
              type: 'select',
              id: 'vehicleCondition20',
              columnInfo: this.detailInfo.vehicle_condition20,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
          ],
        },
        {
          // 予備タブ
          label: this.detailInfo.tab3.label,
          inputComponents: [
            {
              // 受注オーダ明細予備1
              type: 'text',
              id: 'reserve01',
              columnInfo: this.detailInfo.reserve01,
            },
            {
              // 受注オーダ明細予備2
              type: 'text',
              id: 'reserve02',
              columnInfo: this.detailInfo.reserve02,
            },
            {
              // 受注オーダ明細予備3
              type: 'text',
              id: 'reserve03',
              columnInfo: this.detailInfo.reserve03,
            },
            {
              // 受注オーダ明細予備4
              type: 'text',
              id: 'reserve04',
              columnInfo: this.detailInfo.reserve04,
            },
            {
              // 受注オーダ明細予備5
              type: 'text',
              id: 'reserve05',
              columnInfo: this.detailInfo.reserve05,
            },
            {
              // 受注オーダ明細予備6
              type: 'text',
              id: 'reserve06',
              columnInfo: this.detailInfo.reserve06,
            },
            {
              // 受注オーダ明細予備7
              type: 'text',
              id: 'reserve07',
              columnInfo: this.detailInfo.reserve07,
            },
            {
              // 受注オーダ明細予備8
              type: 'text',
              id: 'reserve08',
              columnInfo: this.detailInfo.reserve08,
            },
            {
              // 受注オーダ明細予備9
              type: 'text',
              id: 'reserve09',
              columnInfo: this.detailInfo.reserve09,
            },
            {
              // 受注オーダ明細予備10
              type: 'text',
              id: 'reserve10',
              columnInfo: this.detailInfo.reserve10,
            },
            {
              // 受注オーダ明細予備11
              type: 'text',
              id: 'reserve11',
              columnInfo: this.detailInfo.reserve11,
            },
            {
              // 受注オーダ明細予備12
              type: 'text',
              id: 'reserve12',
              columnInfo: this.detailInfo.reserve12,
            },
            {
              // 受注オーダ明細予備13
              type: 'text',
              id: 'reserve13',
              columnInfo: this.detailInfo.reserve13,
            },
            {
              // 受注オーダ明細予備14
              type: 'text',
              id: 'reserve14',
              columnInfo: this.detailInfo.reserve14,
            },
            {
              // 受注オーダ明細予備15
              type: 'text',
              id: 'reserve15',
              columnInfo: this.detailInfo.reserve15,
            },
            {
              // 受注オーダ明細予備16
              type: 'text',
              id: 'reserve16',
              columnInfo: this.detailInfo.reserve16,
            },
            {
              // 受注オーダ明細予備17
              type: 'text',
              id: 'reserve17',
              columnInfo: this.detailInfo.reserve17,
            },
            {
              // 受注オーダ明細予備18
              type: 'text',
              id: 'reserve18',
              columnInfo: this.detailInfo.reserve18,
            },
            {
              // 受注オーダ明細予備19
              type: 'text',
              id: 'reserve19',
              columnInfo: this.detailInfo.reserve19,
            },
            {
              // 受注オーダ明細予備20
              type: 'text',
              id: 'reserve20',
              columnInfo: this.detailInfo.reserve20,
            },
            {
              // 受注オーダ明細予備21
              type: 'text',
              id: 'reserve21',
              columnInfo: this.detailInfo.reserve21,
            },
            {
              // 受注オーダ明細予備22
              type: 'text',
              id: 'reserve22',
              columnInfo: this.detailInfo.reserve22,
            },
            {
              // 受注オーダ明細予備23
              type: 'text',
              id: 'reserve23',
              columnInfo: this.detailInfo.reserve23,
            },
            {
              // 受注オーダ明細予備24
              type: 'text',
              id: 'reserve24',
              columnInfo: this.detailInfo.reserve24,
            },
            {
              // 受注オーダ明細予備25
              type: 'text',
              id: 'reserve25',
              columnInfo: this.detailInfo.reserve25,
            },
            {
              // 受注オーダ明細予備26
              type: 'text',
              id: 'reserve26',
              columnInfo: this.detailInfo.reserve26,
            },
            {
              // 受注オーダ明細予備27
              type: 'text',
              id: 'reserve27',
              columnInfo: this.detailInfo.reserve27,
            },
            {
              // 受注オーダ明細予備28
              type: 'text',
              id: 'reserve28',
              columnInfo: this.detailInfo.reserve28,
            },
            {
              // 受注オーダ明細予備29
              type: 'text',
              id: 'reserve29',
              columnInfo: this.detailInfo.reserve29,
            },
            {
              // 受注オーダ明細予備30
              type: 'text',
              id: 'reserve30',
              columnInfo: this.detailInfo.reserve30,
            },
          ],
        },
      ]
    },

    detailInfo() {
      let info = this.$store.getters['init/getScreens'](screenId)('detail_info')
      return info
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'result_ctrl_info'
      )
    },
  },

  methods: {
    getListOptions,
    init,
    openSubModal,
    setting,

    afterCloseSetPlace(selectItem) {
      if (this.placeModalKbn == 1) {
        this.obj.receiveOrderDetailInfo.departurePlaceId = selectItem.place_id
        this.obj.receiveOrderDetailInfo.departurePlaceCode =
          selectItem.place_code
        this.obj.receiveOrderDetailInfo.departurePlaceName =
          selectItem.place_name
      } else if (this.placeModalKbn == 2) {
        this.obj.receiveOrderDetailInfo.arrivalPlaceId = selectItem.place_id
        this.obj.receiveOrderDetailInfo.arrivalPlaceCode = selectItem.place_code
        this.obj.receiveOrderDetailInfo.arrivalPlaceName = selectItem.place_name
      }
    },
    afterCloseUnsetPlace() {
      if (this.placeModalKbn == 1) {
        this.obj.receiveOrderDetailInfo.departurePlaceId = null
        this.obj.receiveOrderDetailInfo.departurePlaceCode = null
        this.obj.receiveOrderDetailInfo.departurePlaceName = null
      } else if (this.placeModalKbn == 2) {
        this.obj.receiveOrderDetailInfo.arrivalPlaceId = null
        this.obj.receiveOrderDetailInfo.arrivalPlaceCode = null
        this.obj.receiveOrderDetailInfo.arrivalPlaceName = null
      }
    },

    afterCloseSetDepartment(id, code, name) {
      this.obj.receiveOrderDetailInfo.assignCarrierId = id
      this.obj.receiveOrderDetailInfo.assignCarrierCode = code
      this.obj.receiveOrderDetailInfo.assignCarrierName = name
    },
    afterCloseUnsetDepartment() {
      this.obj.receiveOrderDetailInfo.assignCarrierId = null
      this.obj.receiveOrderDetailInfo.assignCarrierCode = null
      this.obj.receiveOrderDetailInfo.assignCarrierName = null
    },

    afterCloseSetVehicle(selectItem) {
      this.obj.receiveOrderDetailInfo.assignVehicleId = selectItem.vehicle_id
      this.obj.receiveOrderDetailInfo.assignVehicleCode =
        selectItem.vehicle_code
      this.obj.receiveOrderDetailInfo.assignVehicleName =
        selectItem.vehicle_name
    },
    afterCloseUnsetVehicle() {
      this.obj.receiveOrderDetailInfo.assignVehicleId = null
      this.obj.receiveOrderDetailInfo.assignVehicleCode = null
      this.obj.receiveOrderDetailInfo.assignVehicleName = null
    },

    afterCloseSetProduct(selectItem) {
      this.obj.receiveOrderDetailInfo.productId = selectItem[0].product_id
      this.obj.receiveOrderDetailInfo.productCode = selectItem[0].product_code
      this.obj.receiveOrderDetailInfo.productName = selectItem[0].product_name
      this.obj.receiveOrderDetailInfo.quantity = 1
      this.obj.receiveOrderDetailInfo.volume = selectItem[0].volume
        ? selectItem[0].volume
        : 1
      this.obj.receiveOrderDetailInfo.weight = selectItem[0].weight
        ? selectItem[0].weight
        : 1

      this.obj.receiveOrderDetailInfo.length = selectItem[0].length
        ? selectItem[0].length
        : 1
      this.obj.receiveOrderDetailInfo.width = selectItem[0].width
        ? selectItem[0].width
        : 1
      this.obj.receiveOrderDetailInfo.height = selectItem[0].height
        ? selectItem[0].height
        : 1
      this.obj.receiveOrderDetailInfo.autoSectionDivisions =
        selectItem[0].auto_section_divisions
      this.obj.receiveOrderDetailInfo.autoPlanningExcludeFlag =
        selectItem[0].auto_planning_exclude_flag
      this.obj.receiveOrderDetailInfo.warningMessage =
        selectItem[0].warning_message
      this.obj.receiveOrderDetailInfo.note = selectItem[0].note

      this.obj.receiveOrderDetailInfo.tariffProductCode =
        selectItem[0].tariff_product_code

      // 輸送手段０１〜輸送手段２０
      this.obj.receiveOrderDetailInfo.vehicleCondition01 =
        selectItem[0].vehicle_condition01
      this.obj.receiveOrderDetailInfo.vehicleCondition02 =
        selectItem[0].vehicle_condition02
      this.obj.receiveOrderDetailInfo.vehicleCondition03 =
        selectItem[0].vehicle_condition03
      this.obj.receiveOrderDetailInfo.vehicleCondition04 =
        selectItem[0].vehicle_condition04
      this.obj.receiveOrderDetailInfo.vehicleCondition05 =
        selectItem[0].vehicle_condition05
      this.obj.receiveOrderDetailInfo.vehicleCondition06 =
        selectItem[0].vehicle_condition06
      this.obj.receiveOrderDetailInfo.vehicleCondition07 =
        selectItem[0].vehicle_condition07
      this.obj.receiveOrderDetailInfo.vehicleCondition08 =
        selectItem[0].vehicle_condition08
      this.obj.receiveOrderDetailInfo.vehicleCondition09 =
        selectItem[0].vehicle_condition09
      this.obj.receiveOrderDetailInfo.vehicleCondition10 =
        selectItem[0].vehicle_condition10
      this.obj.receiveOrderDetailInfo.vehicleCondition11 =
        selectItem[0].vehicle_condition11
      this.obj.receiveOrderDetailInfo.vehicleCondition12 =
        selectItem[0].vehicle_condition12
      this.obj.receiveOrderDetailInfo.vehicleCondition13 =
        selectItem[0].vehicle_condition13
      this.obj.receiveOrderDetailInfo.vehicleCondition14 =
        selectItem[0].vehicle_condition14
      this.obj.receiveOrderDetailInfo.vehicleCondition15 =
        selectItem[0].vehicle_condition15
      this.obj.receiveOrderDetailInfo.vehicleCondition16 =
        selectItem[0].vehicle_condition16
      this.obj.receiveOrderDetailInfo.vehicleCondition17 =
        selectItem[0].vehicle_condition17
      this.obj.receiveOrderDetailInfo.vehicleCondition18 =
        selectItem[0].vehicle_condition18
      this.obj.receiveOrderDetailInfo.vehicleCondition19 =
        selectItem[0].vehicle_condition19
      this.obj.receiveOrderDetailInfo.vehicleCondition20 =
        selectItem[0].vehicle_condition20

      // C条件、D条件が設定された場合、高速道路使用可否フラグ「 0 : 利用しない」設定
      if (
        selectItem[0].vehicle_condition11 == VEHICLE_CONDITION.ENABLE ||
        selectItem[0].vehicle_condition12 == VEHICLE_CONDITION.ENABLE
      ) {
        this.obj.receiveOrderDetailInfo.highwayUseYnFlag =
          HIGHWAY_USE_YN_FLAG.NOT_USED
      }
    },
    afterCloseUnsetProduct() {
      this.obj.receiveOrderDetailInfo.productId = null
      this.obj.receiveOrderDetailInfo.productCode = null
      this.obj.receiveOrderDetailInfo.productName = null
      this.obj.receiveOrderDetailInfo.quantity = 0
      this.obj.receiveOrderDetailInfo.volume = 0
      this.obj.receiveOrderDetailInfo.weight = 0
      this.obj.receiveOrderDetailInfo.length = 0
      this.obj.receiveOrderDetailInfo.width = 0
      this.obj.receiveOrderDetailInfo.height = 0
      this.obj.receiveOrderDetailInfo.warningMessage = null
      this.obj.receiveOrderDetailInfo.note = null
      this.obj.receiveOrderDetailInfo.tariffProductCode = null

      // 輸送手段０１〜輸送手段２０
      this.obj.receiveOrderDetailInfo.vehicleCondition01 = null
      this.obj.receiveOrderDetailInfo.vehicleCondition02 = null
      this.obj.receiveOrderDetailInfo.vehicleCondition03 = null
      this.obj.receiveOrderDetailInfo.vehicleCondition04 = null
      this.obj.receiveOrderDetailInfo.vehicleCondition05 = null
      this.obj.receiveOrderDetailInfo.vehicleCondition06 = null
      this.obj.receiveOrderDetailInfo.vehicleCondition07 = null
      this.obj.receiveOrderDetailInfo.vehicleCondition08 = null
      this.obj.receiveOrderDetailInfo.vehicleCondition09 = null
      this.obj.receiveOrderDetailInfo.vehicleCondition10 = null
      this.obj.receiveOrderDetailInfo.vehicleCondition11 = null
      this.obj.receiveOrderDetailInfo.vehicleCondition12 = null
      this.obj.receiveOrderDetailInfo.vehicleCondition13 = null
      this.obj.receiveOrderDetailInfo.vehicleCondition14 = null
      this.obj.receiveOrderDetailInfo.vehicleCondition15 = null
      this.obj.receiveOrderDetailInfo.vehicleCondition16 = null
      this.obj.receiveOrderDetailInfo.vehicleCondition17 = null
      this.obj.receiveOrderDetailInfo.vehicleCondition18 = null
      this.obj.receiveOrderDetailInfo.vehicleCondition19 = null
      this.obj.receiveOrderDetailInfo.vehicleCondition20 = null
      // 高速道路使用可否フラグ「 1 : 利用する」設定
      this.obj.receiveOrderDetailInfo.highwayUseYnFlag =
        HIGHWAY_USE_YN_FLAG.USED
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },
  },
}
</script>

<style scoped></style>
