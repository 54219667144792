import {
  // AUTO_PLANNING_EXCLUDE_FLAG,
  HIGHWAY_USE_YN_FLAG,
  GO_OR_BACK,
  IS_MULTIPLE_LOADING,
  PROCESS_STATUS,
  IS_SIMULATION_DATA,
  LOCK_FLAG,
} from '@/common/const.js'
export default class receiveOrderDetail {
  quantity = 0
  weight = 0
  volume = 0
  length = 0
  width = 0
  height = 0
  note = null
  productId = null
  productCode = null
  productName = null
  autoSectionDivisions = null
  tariffProductCode = null
  warningMessage = null

  //Edit
  departureBeginDateEdit = null
  departureBeginTimeEdit = null
  departureEndDateEdit = null
  departureEndTimeEdit = null

  departurePlaceId = null
  departurePlaceCode = null
  departurePlaceName = null
  departurePlaceAssignFlag = null
  departureTimeAssignKind = null
  departureTimeStrict = null

  //Edit
  arrivalBeginDateEdit = null
  arrivalBeginTimeEdit = null
  arrivalEndDateEdit = null
  arrivalEndTimeEdit = null

  arrivalPlaceId = null
  arrivalPlaceCode = null
  arrivalPlaceName = null
  arrivalTimeAssignKind = null
  arrivalTimeStrict = null
  departureCondition = null
  arrivalCondition = null
  assignTripNo = null
  assignDropNo = null
  canceled = '0'
  autoPlanningExcludeFlag = null
  autoPlanningExcludeFlagName = null
  vehicleCategory = null
  preloadKind = null
  preloadDate = null
  preloadBeginTime = null
  //高速道路使用可否フラグ // 1 : 利用する
  highwayUseYnFlag = HIGHWAY_USE_YN_FLAG.USED
  //オーダー距離
  orderDistance = 0
  //往復区分 // 往路 = 0
  onewayOrRound = GO_OR_BACK.GOWAY
  //2台積区分 // 1台積
  isMultipleLoading = IS_MULTIPLE_LOADING.LOAD_ONE
  assignVehicleId = null
  assignVehicleName = null
  assignVehicleCode = null
  assignCarrierId = null
  assignCarrierCode = null
  assignCarrierName = null
  //シミュレーション取込データフラグ  // それ以外0
  isSimulationData = IS_SIMULATION_DATA.ETC
  processStatus = PROCESS_STATUS.DONE // 0：完了
  lockFlag = LOCK_FLAG.NORMAL // 0：通常
  deleted = 0

  vehicleCondition01 = null
  vehicleCondition02 = null
  vehicleCondition03 = null
  vehicleCondition04 = null
  vehicleCondition05 = null
  vehicleCondition06 = null
  vehicleCondition07 = null
  vehicleCondition08 = null
  vehicleCondition09 = null
  vehicleCondition10 = null
  vehicleCondition11 = null
  vehicleCondition12 = null
  vehicleCondition13 = null
  vehicleCondition14 = null
  vehicleCondition15 = null
  vehicleCondition16 = null
  vehicleCondition17 = null
  vehicleCondition18 = null
  vehicleCondition19 = null
  vehicleCondition20 = null
  reserve01 = null
  reserve02 = null
  reserve03 = null
  reserve04 = null
  reserve05 = null
  reserve06 = null
  reserve07 = null
  reserve08 = null
  reserve09 = null
  reserve10 = null
  reserve11 = null
  reserve12 = null
  reserve13 = null
  reserve14 = null
  reserve15 = null
  reserve16 = null
  reserve17 = null
  reserve18 = null
  reserve19 = null
  reserve20 = null
  reserve21 = null
  reserve22 = null
  reserve23 = null
  reserve24 = null
  reserve25 = null
  reserve26 = null
  reserve27 = null
  reserve28 = null
  reserve29 = null
  reserve30 = null
}
