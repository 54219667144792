import { formatYYYYMMDD, formatHHmmss } from '@/common/Common.js'
import {
  DETAIL_SCREEN_KBN,
  //AUTO_PLANNING_EXCLUDE_FLAG,
  HIGHWAY_USE_YN_FLAG,
  GO_OR_BACK,
  IS_MULTIPLE_LOADING,
  IS_SIMULATION_DATA,
} from '@/common/const.js'
//import { openMsgModal } from '@/common/Common.js'
import ReceiveOrderDetailInfo from './ReceiveOrderDetailInfo'

export function init(obj) {
  obj.selectRowIdx = obj.getDatas[0].detailRowIndex
  obj.tableItems = obj.getDatas[0].obj.tableItems
  if (obj.getDatas[0].detailScreenKbn == DETAIL_SCREEN_KBN.NEW) {
    //追加
    obj.receiveOrderDetailInfo = new ReceiveOrderDetailInfo()
    //自動配車対象外フラグ
    //obj.receiveOrderDetailInfo.autoPlanningExcludeFlag =
    //  AUTO_PLANNING_EXCLUDE_FLAG.COVERD
    //高速道路使用可否フラグ // 1 : 利用する
    obj.receiveOrderDetailInfo.highwayUseYnFlag = HIGHWAY_USE_YN_FLAG.USED
    //オーダー距離
    obj.receiveOrderDetailInfo.orderDistance = 0
    //往復区分 // 往路 = 0
    obj.receiveOrderDetailInfo.onewayOrRound = GO_OR_BACK.GOWAY
    //2台積区分 // 1台積
    obj.receiveOrderDetailInfo.isMultipleLoading = IS_MULTIPLE_LOADING.LOAD_ONE
  } else {
    //編集
    obj.receiveOrderDetailInfo.productId =
      obj.getDatas[0].selectedItem[0].product_id
    obj.receiveOrderDetailInfo.productName =
      obj.getDatas[0].selectedItem[0].product_name
    obj.receiveOrderDetailInfo.productCode =
      obj.getDatas[0].selectedItem[0].product_code
    obj.receiveOrderDetailInfo.tariffProductCode =
      obj.getDatas[0].selectedItem[0].tariff_product_code
    obj.receiveOrderDetailInfo.quantity =
      obj.getDatas[0].selectedItem[0].quantity
    obj.receiveOrderDetailInfo.weight = obj.getDatas[0].selectedItem[0].weight
    obj.receiveOrderDetailInfo.volume = obj.getDatas[0].selectedItem[0].volume

    obj.receiveOrderDetailInfo.length = obj.getDatas[0].selectedItem[0].length
    obj.receiveOrderDetailInfo.width = obj.getDatas[0].selectedItem[0].width
    obj.receiveOrderDetailInfo.height = obj.getDatas[0].selectedItem[0].height

    obj.receiveOrderDetailInfo.autoSectionDivisions =
      obj.getDatas[0].selectedItem[0].auto_section_divisions

    obj.receiveOrderDetailInfo.note = obj.getDatas[0].selectedItem[0].note
    obj.receiveOrderDetailInfo.warningMessage =
      obj.getDatas[0].selectedItem[0].warning_message

    obj.receiveOrderDetailInfo.departureBeginDateEdit =
      obj.getDatas[0].selectedItem[0].departure_begin_date
    obj.receiveOrderDetailInfo.departureBeginTimeEdit = changeDisplayTime(
      obj.getDatas[0].selectedItem[0].departure_begin_time
    )
    obj.receiveOrderDetailInfo.departureEndDateEdit =
      obj.getDatas[0].selectedItem[0].departure_end_date
    obj.receiveOrderDetailInfo.departureEndTimeEdit = changeDisplayTime(
      obj.getDatas[0].selectedItem[0].departure_end_time
    )
    obj.receiveOrderDetailInfo.departurePlaceId =
      obj.getDatas[0].selectedItem[0].departure_place_id
    obj.receiveOrderDetailInfo.departurePlaceCode =
      obj.getDatas[0].selectedItem[0].departure_place_code
    obj.receiveOrderDetailInfo.departurePlaceName =
      obj.getDatas[0].selectedItem[0].departure_place_name
    obj.receiveOrderDetailInfo.departurePlaceAssignFlag =
      obj.getDatas[0].selectedItem[0].departure_place_assign_flag
    obj.receiveOrderDetailInfo.departureTimeAssignKind =
      obj.getDatas[0].selectedItem[0].departure_time_assign_kind
    obj.receiveOrderDetailInfo.departureTimeStrict =
      obj.getDatas[0].selectedItem[0].departure_time_strict

    //Edit
    obj.receiveOrderDetailInfo.arrivalBeginDateEdit =
      obj.getDatas[0].selectedItem[0].arrival_begin_date
    obj.receiveOrderDetailInfo.arrivalBeginTimeEdit = changeDisplayTime(
      obj.getDatas[0].selectedItem[0].arrival_begin_time
    )
    obj.receiveOrderDetailInfo.arrivalEndDateEdit =
      obj.getDatas[0].selectedItem[0].arrival_end_date
    obj.receiveOrderDetailInfo.arrivalEndTimeEdit = changeDisplayTime(
      obj.getDatas[0].selectedItem[0].arrival_end_time
    )

    obj.receiveOrderDetailInfo.canceled =
      obj.getDatas[0].selectedItem[0].canceled
    obj.receiveOrderDetailInfo.vehicleCategory =
      obj.getDatas[0].selectedItem[0].vehicle_category

    obj.receiveOrderDetailInfo.arrivalPlaceId =
      obj.getDatas[0].selectedItem[0].arrival_place_id
    obj.receiveOrderDetailInfo.arrivalPlaceCode =
      obj.getDatas[0].selectedItem[0].arrival_place_code
    obj.receiveOrderDetailInfo.arrivalPlaceName =
      obj.getDatas[0].selectedItem[0].arrival_place_name
    obj.receiveOrderDetailInfo.arrivalTimeAssignKind =
      obj.getDatas[0].selectedItem[0].arrival_time_assign_kind
    obj.receiveOrderDetailInfo.arrivalTimeStrict =
      obj.getDatas[0].selectedItem[0].arrival_time_strict
    obj.receiveOrderDetailInfo.departureCondition =
      obj.getDatas[0].selectedItem[0].departure_condition
    obj.receiveOrderDetailInfo.arrivalCondition =
      obj.getDatas[0].selectedItem[0].arrival_condition
    obj.receiveOrderDetailInfo.autoPlanningExcludeFlag =
      obj.getDatas[0].selectedItem[0].auto_planning_exclude_flag
    obj.receiveOrderDetailInfo.preloadKind =
      obj.getDatas[0].selectedItem[0].preload_kind
    obj.receiveOrderDetailInfo.preloadDate =
      obj.getDatas[0].selectedItem[0].preload_date
    obj.receiveOrderDetailInfo.preloadBeginTime = changeDisplayTime(
      obj.getDatas[0].selectedItem[0].preload_begin_time
    )
    obj.receiveOrderDetailInfo.vehicleCondition01 =
      obj.getDatas[0].selectedItem[0].vehicle_condition01
    obj.receiveOrderDetailInfo.vehicleCondition02 =
      obj.getDatas[0].selectedItem[0].vehicle_condition02
    obj.receiveOrderDetailInfo.vehicleCondition03 =
      obj.getDatas[0].selectedItem[0].vehicle_condition03
    obj.receiveOrderDetailInfo.vehicleCondition04 =
      obj.getDatas[0].selectedItem[0].vehicle_condition04
    obj.receiveOrderDetailInfo.vehicleCondition05 =
      obj.getDatas[0].selectedItem[0].vehicle_condition05
    obj.receiveOrderDetailInfo.vehicleCondition06 =
      obj.getDatas[0].selectedItem[0].vehicle_condition06
    obj.receiveOrderDetailInfo.vehicleCondition07 =
      obj.getDatas[0].selectedItem[0].vehicle_condition07
    obj.receiveOrderDetailInfo.vehicleCondition08 =
      obj.getDatas[0].selectedItem[0].vehicle_condition08
    obj.receiveOrderDetailInfo.vehicleCondition09 =
      obj.getDatas[0].selectedItem[0].vehicle_condition09
    obj.receiveOrderDetailInfo.vehicleCondition10 =
      obj.getDatas[0].selectedItem[0].vehicle_condition10
    obj.receiveOrderDetailInfo.vehicleCondition11 =
      obj.getDatas[0].selectedItem[0].vehicle_condition11
    obj.receiveOrderDetailInfo.vehicleCondition12 =
      obj.getDatas[0].selectedItem[0].vehicle_condition12
    obj.receiveOrderDetailInfo.vehicleCondition13 =
      obj.getDatas[0].selectedItem[0].vehicle_condition13
    obj.receiveOrderDetailInfo.vehicleCondition14 =
      obj.getDatas[0].selectedItem[0].vehicle_condition14
    obj.receiveOrderDetailInfo.vehicleCondition15 =
      obj.getDatas[0].selectedItem[0].vehicle_condition15
    obj.receiveOrderDetailInfo.vehicleCondition16 =
      obj.getDatas[0].selectedItem[0].vehicle_condition16
    obj.receiveOrderDetailInfo.vehicleCondition17 =
      obj.getDatas[0].selectedItem[0].vehicle_condition17
    obj.receiveOrderDetailInfo.vehicleCondition18 =
      obj.getDatas[0].selectedItem[0].vehicle_condition18
    obj.receiveOrderDetailInfo.vehicleCondition19 =
      obj.getDatas[0].selectedItem[0].vehicle_condition19
    obj.receiveOrderDetailInfo.vehicleCondition20 =
      obj.getDatas[0].selectedItem[0].vehicle_condition20
    obj.receiveOrderDetailInfo.reserve01 =
      obj.getDatas[0].selectedItem[0].reserve01
    obj.receiveOrderDetailInfo.reserve02 =
      obj.getDatas[0].selectedItem[0].reserve02
    obj.receiveOrderDetailInfo.reserve03 =
      obj.getDatas[0].selectedItem[0].reserve03
    obj.receiveOrderDetailInfo.reserve04 =
      obj.getDatas[0].selectedItem[0].reserve04
    obj.receiveOrderDetailInfo.reserve05 =
      obj.getDatas[0].selectedItem[0].reserve05
    obj.receiveOrderDetailInfo.reserve06 =
      obj.getDatas[0].selectedItem[0].reserve06
    obj.receiveOrderDetailInfo.reserve07 =
      obj.getDatas[0].selectedItem[0].reserve07
    obj.receiveOrderDetailInfo.reserve08 =
      obj.getDatas[0].selectedItem[0].reserve08
    obj.receiveOrderDetailInfo.reserve09 =
      obj.getDatas[0].selectedItem[0].reserve09
    obj.receiveOrderDetailInfo.reserve10 =
      obj.getDatas[0].selectedItem[0].reserve10

    obj.receiveOrderDetailInfo.reserve11 =
      obj.getDatas[0].selectedItem[0].reserve11
    obj.receiveOrderDetailInfo.reserve12 =
      obj.getDatas[0].selectedItem[0].reserve12
    obj.receiveOrderDetailInfo.reserve13 =
      obj.getDatas[0].selectedItem[0].reserve13
    obj.receiveOrderDetailInfo.reserve14 =
      obj.getDatas[0].selectedItem[0].reserve14
    obj.receiveOrderDetailInfo.reserve15 =
      obj.getDatas[0].selectedItem[0].reserve15
    obj.receiveOrderDetailInfo.reserve16 =
      obj.getDatas[0].selectedItem[0].reserve16
    obj.receiveOrderDetailInfo.reserve17 =
      obj.getDatas[0].selectedItem[0].reserve17
    obj.receiveOrderDetailInfo.reserve18 =
      obj.getDatas[0].selectedItem[0].reserve18
    obj.receiveOrderDetailInfo.reserve19 =
      obj.getDatas[0].selectedItem[0].reserve19
    obj.receiveOrderDetailInfo.reserve20 =
      obj.getDatas[0].selectedItem[0].reserve20
    obj.receiveOrderDetailInfo.reserve21 =
      obj.getDatas[0].selectedItem[0].reserve21
    obj.receiveOrderDetailInfo.reserve22 =
      obj.getDatas[0].selectedItem[0].reserve22
    obj.receiveOrderDetailInfo.reserve23 =
      obj.getDatas[0].selectedItem[0].reserve23
    obj.receiveOrderDetailInfo.reserve24 =
      obj.getDatas[0].selectedItem[0].reserve24
    obj.receiveOrderDetailInfo.reserve25 =
      obj.getDatas[0].selectedItem[0].reserve25
    obj.receiveOrderDetailInfo.reserve26 =
      obj.getDatas[0].selectedItem[0].reserve26
    obj.receiveOrderDetailInfo.reserve27 =
      obj.getDatas[0].selectedItem[0].reserve27
    obj.receiveOrderDetailInfo.reserve28 =
      obj.getDatas[0].selectedItem[0].reserve28
    obj.receiveOrderDetailInfo.reserve29 =
      obj.getDatas[0].selectedItem[0].reserve29
    obj.receiveOrderDetailInfo.reserve30 =
      obj.getDatas[0].selectedItem[0].reserve30

    obj.receiveOrderDetailInfo.assignTripNo =
      obj.getDatas[0].selectedItem[0].assign_trip_no
    obj.receiveOrderDetailInfo.assignDropNo =
      obj.getDatas[0].selectedItem[0].assign_drop_no
    // obj.receiveOrderDetailInfo.orderKind =
    //   obj.getDatas[0].selectedItem[0].order_kind
    // obj.receiveOrderDetailInfo.preloadAssignDate =
    //   obj.getDatas[0].selectedItem[0].preload_assign_date
    obj.receiveOrderDetailInfo.assignVehicleId =
      obj.getDatas[0].selectedItem[0].assign_vehicle_id
    obj.receiveOrderDetailInfo.assignVehicleCode =
      obj.getDatas[0].selectedItem[0].assign_vehicle_code
    obj.receiveOrderDetailInfo.assignVehicleName =
      obj.getDatas[0].selectedItem[0].assign_vehicle_name
    obj.receiveOrderDetailInfo.assignCarrierId =
      obj.getDatas[0].selectedItem[0].assign_carrier_id
    obj.receiveOrderDetailInfo.assignCarrierName =
      obj.getDatas[0].selectedItem[0].assign_carrier_name
    obj.receiveOrderDetailInfo.assignCarrierCode =
      obj.getDatas[0].selectedItem[0].assign_carrier_code

    //add columns
    //シミュレーション取込データフラグ  // それ以外0
    obj.receiveOrderDetailInfo.isSimulationData = IS_SIMULATION_DATA.ETC
    obj.receiveOrderDetailInfo.highwayUseYnFlag =
      obj.getDatas[0].selectedItem[0].highway_use_yn_flag
    obj.receiveOrderDetailInfo.orderDistance =
      obj.getDatas[0].selectedItem[0].order_distance
    obj.receiveOrderDetailInfo.onewayOrRound =
      obj.getDatas[0].selectedItem[0].oneway_or_round
    obj.receiveOrderDetailInfo.isMultipleLoading =
      obj.getDatas[0].selectedItem[0].is_multiple_loading
  }

  // validate reset
  obj.$refs['tab-group'].$refs['base-tab-group'].reset()
}

function changeDisplayTime(hhmmss) {
  if (hhmmss) {
    let time = hhmmss.match(/\d{2}/g).join(':')
    return time
  }
}

export async function setting(obj) {
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  // 出発情報
  obj.receiveOrderDetailInfo.departureBeginDateEdit = formatYYYYMMDD(
    obj,
    obj.receiveOrderDetailInfo.departureBeginDateEdit
  )
  obj.receiveOrderDetailInfo.departureEndDateEdit = formatYYYYMMDD(
    obj,
    obj.receiveOrderDetailInfo.departureEndDateEdit
  )
  obj.receiveOrderDetailInfo.departureBeginTimeEdit = formatHHmmss(
    obj.receiveOrderDetailInfo.departureBeginTimeEdit
  )
  obj.receiveOrderDetailInfo.departureEndTimeEdit = formatHHmmss(
    obj.receiveOrderDetailInfo.departureEndTimeEdit
  )

  // 到着情報
  obj.receiveOrderDetailInfo.arrivalBeginDateEdit = formatYYYYMMDD(
    obj,
    obj.receiveOrderDetailInfo.arrivalBeginDateEdit
  )
  obj.receiveOrderDetailInfo.arrivalEndDateEdit = formatYYYYMMDD(
    obj,
    obj.receiveOrderDetailInfo.arrivalEndDateEdit
  )
  obj.receiveOrderDetailInfo.arrivalBeginTimeEdit = formatHHmmss(
    obj.receiveOrderDetailInfo.arrivalBeginTimeEdit
  )
  obj.receiveOrderDetailInfo.arrivalEndTimeEdit = formatHHmmss(
    obj.receiveOrderDetailInfo.arrivalEndTimeEdit
  )

  // 積置き情報
  obj.receiveOrderDetailInfo.preloadDate = formatYYYYMMDD(
    obj,
    obj.receiveOrderDetailInfo.preloadDate
  )
  obj.receiveOrderDetailInfo.preloadBeginTime = formatHHmmss(
    obj.receiveOrderDetailInfo.preloadBeginTime
  )

  // 呼出元へ
  obj.$bvModal.hide('receive-order-detail-edit')
  obj.$emit('after-close-set', obj.receiveOrderDetailInfo)
}

// /**
//  * 稼動時間が5日を越える場合、エラーとする
//  * @param {*} startT
//  * @param {*} endT
//  * @param {*} beginWeekday
//  * @param {*} endWeekday
//  */
// function dateCheck(startT, endT, beginWeekday, endWeekday) {
//     var days = beginWeekday - endWeekday

//     var startTime = changeDate(startT)
//     var endTime = changeDate(endT)
//     let sec = startTime - endTime

//     if (0 < days || days < -5) {
//         return 'error'
//     }
//     days = Math.abs(days)
//     sec = Math.abs(sec)
//     var betweenDays = minsBetween(days, sec)
//     let error

//     if (betweenDays > 5 || betweenDays < 0) {
//         return 'error'
//     }

//     return error
// }

// function changeDate(time) {
//     //let date = new Date()
//     let a = time.split(':')
//     let seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2]
//         //date = date.setSeconds(date.getSeconds() + seconds)
//     return seconds
// }

// function minsBetween(days, sec) {
//     var duration = days * 24 * 60 * 60 * 1000 + sec
//     return duration / (1000 * 60 * 60 * 24)
// }
